// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/johnwoodruff/dev/frontend/driblet-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authcallback-js": () => import("/Users/johnwoodruff/dev/frontend/driblet-site/src/pages/authcallback.js" /* webpackChunkName: "component---src-pages-authcallback-js" */),
  "component---src-pages-index-js": () => import("/Users/johnwoodruff/dev/frontend/driblet-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/johnwoodruff/dev/frontend/driblet-site/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import("/Users/johnwoodruff/dev/frontend/driblet-site/.cache/data.json")

